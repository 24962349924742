<template>
    <div>
      <reject-reason-comp :type="type"></reject-reason-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {checkPermission} from "@/core/helpers/general";
import RejectReasonComp from "@/components/admin/reject_reason/RejectReasonComp";

export default defineComponent({
    name: "view-reject-reason",
    components : {RejectReasonComp},

    setup() {

        const type = ref('view');
        onMounted(() => {
            setCurrentPageBreadcrumbs("View Reject Reason", ["Reject Reasons"] , checkPermission('reject-reasons.store') ? 'create-reject-reason': null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
